import React from 'react';
import { useSelector } from 'react-redux';
import { ExternalScripts } from 'mm-ui-components';
import {
  getExternalScripts,
  getExperiments,
  getCanonicalUrl,
} from '../../store/config/config.selectors';

const ONE_TRUST_SITES = [
  'skywayshoutout.com', // FS site with no traffic
  'ninernoise.com',
];

export function getConsentProvider(
  canonicalUrl: string,
  experiments: string[] | null,
): string {
  if (ONE_TRUST_SITES.some(site => canonicalUrl && canonicalUrl.includes(site))) {
    return 'onetrust';
  }

  if (experiments && experiments.includes('enable-onetrust|activate')) {
    return 'onetrust';
  }

  return 'didomi';
}

export const HeadScripts: React.FunctionComponent = () => {
  const experiments = useSelector(getExperiments);
  const scripts = useSelector(getExternalScripts);
  const canonicalUrl = useSelector(getCanonicalUrl);

  const consentProvider = getConsentProvider(canonicalUrl, experiments);

  if (experiments && experiments.includes('enable-pushly|activate')) {
    scripts.push({
      name: 'pushly-sdk',
      source:
        'https://cdn.p-n.io/pushly-sdk.min.js?domain_key=8wWmC3z7HUMgeZEt9s42svXvhztLq3YG2N02',
      type: 'URL',
      loadingType: 'async',
      attributes: null,
    });

    scripts.push({
      name: 'pushly-sw',
      source:
        'function pushly(){window.PushlySDK.push(arguments)}window.PushlySDK=window.PushlySDK||[],pushly("load",{domainKey:"8wWmC3z7HUMgeZEt9s42svXvhztLq3YG2N02",sw:"/assets/pushly/pushly-sdk-worker.js"});',
      type: 'code',
      loadingType: 'blocking',
      attributes: null,
    });
  }

  return (
    <ExternalScripts scripts={scripts} consentProvider={consentProvider} />
  );
};
