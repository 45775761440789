import React, { useEffect, useRef, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration/dist/interfaces';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../../../theming/fontSizeTranslator';
import { useTheme } from '../../../../../theming/useTheme';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';

import { Link } from '../../../../partials/link/Link';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { getChevronIconStyle } from './header.util';
import { ChevronIcon } from '../../../../partials/icon/icons';
import { Icon } from '../../../../partials/icon/Icon';


interface MegaMenuProp {
  liLink: NavigationLink;
  megaMenu: NavigationLink[] | null;
  iconColor: string;
  windowsDetails: {origin: string; path: string; innerWidth: number};
}

const getLinksStyle = (colors: Colors, wrapperWidth: string, isLeft: boolean) => {
  return StyleSheet.create({
    menuWrapper: {
      display: 'flex',
      padding: '24px',
      position: 'absolute',
      top: '48px',
      right: isLeft ? `-${wrapperWidth}` : '0',
      left: isLeft ? '0' : `-${wrapperWidth}`,
      gap: '24px',
      zIndex: 10,
      boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
      backgroundColor: colors.white,
      textTransform: 'none',
      flexWrap: 'wrap',
      width: wrapperWidth,
      [MEDIA_BREAKPOINTS.small]: {
        gap: '16px',
        width: '87%',
        left: '0px',
        top: '136px',
      },
    },
    linksAndTitle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      [MEDIA_BREAKPOINTS.small]: {
        gap: '8px',
      },
    },
    linksList: {
      listStyleType: 'none',
      flex: '1',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '4px',
      maxHeight: '230px',
      flexWrap: 'wrap',
      [MEDIA_BREAKPOINTS.small]: {
        gap: '8px',
      },
    },
    title: {
      // @ts-ignore
      fontWeight: '700 !important',
      color: colors.darkGrey,
    },
    link: {
      color: colors.darkGrey,
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      alignItems: 'center',
      textDecoration: 'none',
      width: '150px',
      ':hover': {
        color: colors.primary,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '140px',
      },
    },
    flex: {
      display: 'flex',
      [MEDIA_BREAKPOINTS.small]: {
        paddingTop: '16px',
      },
    },
  });
};

const getChildrenArray = (megaMenu: NavigationLink[]): NavigationLink[] => {
  const result: NavigationLink[] = [];
  let parent: NavigationLink | null = null;
  megaMenu.forEach(item => {
    if (item.link.href === '') {
      parent = item;
      parent.children = [];
      result.push(parent);
    } else if (parent) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(item);
    }
  });
  return result;
};

export const MegaMenuThirdRow: React.FunctionComponent<MegaMenuProp> = ({ liLink, megaMenu, iconColor, windowsDetails }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isLeft, setIsLeft] = useState(false);

  const { colors, fontSizesStyles }: { colors: Colors; fontSizesStyles: TranslatedFontSize } = useTheme();
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.TINY, fontSizeMedium: TYPOGRAPHY_SIZE.TINY, fontSizeLarge: TYPOGRAPHY_SIZE.TINY });
  const links = megaMenu ? getChildrenArray(megaMenu) : null;
  const numOfCol = links ? Math.min(links.length, 3) : 0;
  const wrapperWidth = numOfCol * 150 + (numOfCol - 1) * 24;
  const style = getLinksStyle(colors, `${wrapperWidth}px`, isLeft);

  const iconStyle = getChevronIconStyle(isSubMenuOpen, iconColor);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = (liTitle: HTMLElement) => {
    setIsLeft(liTitle.getBoundingClientRect().x < windowsDetails.innerWidth / 2);
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (menuRef.current && event.target instanceof Node) {
        if (!menuRef.current.contains(event.target)) {
          setIsSubMenuOpen(false);
        }
      }
    };
    if (isSubMenuOpen) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isSubMenuOpen]);

  return (
    <>
      <div onClick={event => toggleMenu(event.currentTarget as HTMLElement)} className={css(style.flex)}>
        <span>{liLink.link.text}</span>
        <Icon icon={ChevronIcon} additionalStyle={iconStyle} />
      </div>
      {isSubMenuOpen
      && (
      <div ref={menuRef} className={css(style.menuWrapper)}>
        {links?.map((navigationLink: NavigationLink, index: number) => {
          return (
            <div key={index} className={css(style.linksAndTitle)}>
              <span className={css(style.title)}>{navigationLink.link.text}</span>
              <ul className={css(style.linksList)}>
                {navigationLink.children?.map((child: NavigationLink, key: number) => {
                  return (
                    <li key={key}>
                      <Link href={child.link.href} className={css(style.link, fontStyle)}>
                        <span>{child.link.text}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      )}
    </>
  );
};
