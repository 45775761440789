import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { useTheme } from 'mm-ui-components/src/theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

const getStyles = (lineColor: string) => {
  return StyleSheet.create({
    divider: {
      height: '1px',
      width: '100vw',
      border: 0,
      backgroundColor: lineColor,
      opacity: 0.5,
      marginLeft: 'calc((100% - 100vw) / 2)',
      marginTop: '40px',
      marginBottom: '30px',
      boxShadow: '0px -2px 5px 0px rgba(0, 0, 0, 0.25)',
    },
  });
};

export const ArticleDivider: React.FunctionComponent = () => {
  const { colors }: { colors: Colors } = useTheme();
  const styles = getStyles(colors.grey);
  return (
    <ErrorBoundary>
      <hr className={css(styles.divider)} />
    </ErrorBoundary>
  );
};
