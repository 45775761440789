export const didomiScript = 'var cc=(document.cookie.match("(^|; )countryCode=([^;]*)")||0)[2];\n'
+ 'if(!window.location.href.includes("si.com")&&(cc&&cc==="US")){window.didomiConfig={notice:{enable:false}};}\n'
+ 'var id="WHiEUdYM";if(window.location.href.includes("www.si.com/high-school")){id="7cbgmDpQ"}window.gdprAppliesGlobally=false;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}\n'
+ '      else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}\n'
+ '    var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}\n'
+ '        if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e,tgt){\n'
+ '        var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+tgt;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("3810dd55-0181-4ddc-952e-59a8c9a36fe4",id)})();\n'
+ '\n'
+ '(function(){function i(c){if(!window.frames[c]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");\n'
+ 'n.style.display="none";n.name=c;n.title=c;t.insertBefore(n,t.firstChild);}else{setTimeout(function(){i(c);},5);}}}\n'
+ 'function e(d,r){let b=[];function k(f){try{let t="enabled";const n=localStorage.getItem("didomi_token_cpra");if(n){\n'
+ 'const l=JSON.parse(atob(n));l?.purposes_li&&Object.keys(l.purposes_li).includes("disabled")&&(t="disabled");}f("disabled"===t?{uspString:"1YYN",version:1}\n'
+ ':{uspString:"1YNN",version:1},!0);}catch(g){}}function t(c,v,a){if(c==="getUSPData"&&v===1){b.push(a);k(a);}}\n'
+ 'window.didomiEventListeners=window.didomiEventListeners||[];window.didomiEventListeners.push({event:"consent.changed",listener:function(e){b.forEach((z)=>{\n'
+ 'k(z);});},});function i(i){if(!window[d]||window[d].stub!==true){return;}if(!i.data){return;}var a=typeof i.data==="string";var e;try{\n'
+ 'e=a?JSON.parse(i.data):i.data;}catch(t){return;}if(e[r]){var o=e[r];window[d](o.command,o.parameter,function(e,t){var n={};n.postMessageReturn={\n'
+ 'returnValue:e,success:t,callId:o.callId,};j.source.postMessage(a?JSON.stringify(n):n,"*");});}}if(typeof window[d]!=="function"){\n'
+ 'window[d]=t;if(window.addEventListener){window.addEventListener("message",i,false);}else{window.attachEvent("onmessage",i);}}}\n'
+ 'e("__uspapi","__uspapiCall");i("__uspapiLocator");})();';
