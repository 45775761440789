import { AppState } from '../initialStateRegistration';

const getConfig = (state: AppState) => state.config;

export const getExternalScripts = (state: AppState) => getConfig(state).externalScripts;

export const getFacebookAppId = (state: AppState) => getConfig(state).facebookAppId;

export const getFacebookPageId = (state: AppState) => getConfig(state).facebookPageId;

export const getSiteName = (state: AppState) => getConfig(state).siteName;

export const getIsFannation = (state: AppState) => getConfig(state).isFannation;

export const getCanonicalUrl = (state: AppState) => getConfig(state).canonicalURL;

export const getFavicon = (state: AppState) => getConfig(state).favicon;

export const getLanguage = (state: AppState) => getConfig(state).language;

export const getTwitterAccount = (state: AppState) => getConfig(state).twitterAccount;

export const getTemplateName = (state: AppState) => getConfig(state).template;

export const getLogo = (state: AppState) => getConfig(state).logo;

export const getTheme = (state: AppState) => getConfig(state).theme;

export const getPalette = (state: AppState) => getTheme(state).palette;

export const getPaletteColors = (state: AppState) => getPalette(state).colors;

export const getPaletteFonts = (state: AppState) => getPalette(state).fonts;

export const getPaletteTypography = (state: AppState) => getPalette(state).typography;

export const getThemeConfiguration = (state: AppState) => getTheme(state).components;

export const getAdSlots = (state: AppState) => getConfig(state).adSlots;

export const getAdSlotId = (slotName: string) => (state: AppState) => (getAdSlots(state)[slotName] && getAdSlots(state)[slotName].id) || null;

export const getAdSlotOnSuccess = (slotName: string) => (state: AppState) => (getAdSlots(state)[slotName] && getAdSlots(state)[slotName].onSuccess) || null;

export const getPageType = (state: AppState) => getConfig(state).pageType;

export const getImagesCdnHost = (state: AppState) => getConfig(state).imagesCDNHost;

export const getAmpURL = (state: AppState) => getConfig(state).ampURL;

export const getPrevCanonicalUrl = (state: AppState) => getConfig(state).prevCanonicalUrl;

export const getAmpDfpProperty = (state: AppState) => getConfig(state).ampDfpProperty;

export const getPagePath = (state: AppState) => getConfig(state).pagePath;

export const getPropertyEndpoint = (state: AppState) => getConfig(state).propertyEndpoint;

export const getTimeZone = (state: AppState) => getConfig(state).timeZone;

export const getLocale = (state: AppState) => getConfig(state).locale;

export const getMidnightInTimeZone = (state: AppState) => getConfig(state).midnightInTimeZone;

export const getEditionEndpoint = (state: AppState) => getConfig(state).editionEndpoint;

export const getEdition = (state: AppState) => getConfig(state).edition;

export const getExperiments = (state: AppState) => getConfig(state).experiments;

export const getResourceID = (state: AppState) => getConfig(state).resourceID;

export const getUseFormatMinutesHoursAgo = (state: AppState) => getConfig(state).useFormatMinutesHoursAgo;

export const getIsMobileViewer = (state: AppState) => getConfig(state).isMobileViewer;
